import React from "react";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { ProSidebarProvider } from "react-pro-sidebar";


/* Disable React Dev Tools if in production */
if (window.injectedEnv.ENVIRONMENT === "production") {
  disableReactDevTools();
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
  </React.StrictMode>
);
