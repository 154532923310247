import React, { createContext, useContext, useState } from 'react';

const ValidationContext = createContext();

export const ValidationProvider = ({ children }) => {
  const [validationErrors, setValidationErrors] = useState({});

  return (
    <ValidationContext.Provider value={{ validationErrors, setValidationErrors }}>
      {children}
    </ValidationContext.Provider>
  );
};

export const useValidation = () => useContext(ValidationContext);
