import React, { useState } from 'react';
import { Tab, Tabs, Paper, useTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SCCATable from './SccaTable';
import HarvestQualityTable from './HarvestQualityTable';
import SCODTable from './ScodTable';
import ReducingSugarsTable from './ReducingSugarsTable';
import ExperimentalPeriodTable from './ExperimentalPeriodTable';
import { ValidationProvider } from './ValidationContext';
import { tokens } from "../../theme";

const ReportsTable = () => {
  const [activeTab, setActiveTab] = useState('sCOD');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const tabStyles = {
    color: colors.primary[100],
    '&.Mui-selected': {
      color: colors.grey[300],
    },
    '&:hover': {
      color: colors.grey[300],
    },
  };

  return (
    <Paper elevation={3} sx={{ padding: 2 }}>
      <ValidationProvider>
      <Tabs
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: colors.grey[300],
            },
          }}
        >
          <Tab label="sCOD" value="sCOD" sx={tabStyles} />
          <Tab label="SCCA" value="SCCA" sx={tabStyles} />
          <Tab label="Harvest Quality" value="HarvestQuality" sx={tabStyles} />
          <Tab label="Reducing Sugars" value="ReducingSugars" sx={tabStyles} />
          <Tab label="Experimental Period" value="ExperimentalPeriod" sx={tabStyles} />
        </ Tabs>

      {activeTab === 'SCCA' && <SCCATable />}
      {activeTab === 'HarvestQuality' && <HarvestQualityTable />}
      {activeTab === 'sCOD' && <SCODTable />}
      {activeTab === 'ReducingSugars' && <ReducingSugarsTable />}
      {activeTab === 'ExperimentalPeriod' && <ExperimentalPeriodTable />}
      </ValidationProvider>
    </Paper>
  );
};

const queryClient = new QueryClient();

const ReportsTableWithProvider = () => (
  <QueryClientProvider client={queryClient}>
    <ReportsTable />
  </QueryClientProvider>
);

export default ReportsTableWithProvider;
