import ReportsTableWithProvider from "../../components/offline_reports/OfflineReport";
import Header from "../../components/Header";

const OfflineReporting = () => {
  return (
    <section>
      <Header
        title="Offline Reporting"
        subtitle="Insert and view Reports"
      />
      <br />
      <ReportsTableWithProvider />
      <br />
    </section>
  );
};

export default OfflineReporting;
