import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Typography, IconButton, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import logoDark from "../../assets/foom.png";
import logoLight from "../../assets/foom_lightmode.png";
import useAuth from "../../hooks/useAuth";
import { tokens } from "../../theme";
import { toPascalCase } from "../../utils/formatting";

const MySidebar = ({ isCollapsed, setIsCollapsed }) => {
  const { auth } = useAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const username = auth.user || "";
  const formattedUsername = toPascalCase(username);
  const userRoles = auth.roles || [];
  const formattedRoles = userRoles.map(toPascalCase).sort().join(", ");


  const handleCollapseSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: colors.primary[800],
        zIndex: 130,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Sidebar
        collapsed={isCollapsed}
        backgroundColor={colors.primary[400]}
        rootStyles={{
          "&": {
            height: "100%",
            borderRightStyle: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <Menu
          menuItemStyles={{
            button: {
              "&:hover": {
                backgroundColor: colors.primary[700],
                color: colors.primary[100],
              },
              "&.active": {
                backgroundColor: colors.primary[700],
                color: colors.grey[100],
              },
              color: colors.grey[100],
            },
          }}
        >
          <MenuItem onClick={handleCollapseSidebar} icon={<MenuOutlinedIcon />}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">
                {theme.palette.mode === "dark" ? (
                  <img src={logoDark} alt="Logo" height="50" />
                ) : (
                  <img src={logoLight} alt="Logo" height="50" />
                )}
              </Typography>
              <IconButton onClick={handleCollapseSidebar} />
            </Box>
          </MenuItem>

          {/* User Profile */}
          <Box textAlign="center" mb="25px">
            <Typography variant="h6" color="inherit">
              {formattedUsername}
            </Typography>
            <Typography variant="subtitle1" color="inherit">
              {formattedRoles}
            </Typography>
          </Box>

          {/* Menu Items */}
          <MenuItem
            icon={<HomeOutlinedIcon />}
            component={<Link to="/" className="link" />}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            icon={<PeopleOutlinedIcon />}
            component={<Link to="/admin" className="link" />}
          >
            Manage Users
          </MenuItem>
          <MenuItem
            icon={<ContactsOutlinedIcon />}
            component={<Link to="/contacts" className="link" />}
          >
            Contacts Information
          </MenuItem>
          <MenuItem
            icon={<ReceiptOutlinedIcon />}
            component={<Link to="/offline-reports" className="link" />}
          >
            Offline Reports
          </MenuItem>
          <MenuItem
            icon={<BackupOutlinedIcon />}
            component={<Link to="/offline-data" className="link" />}
          >
            Offline Data
          </MenuItem>
          <MenuItem
            icon={<BarChartOutlinedIcon />}
            component={<Link to="/bar" className="link" />}
          >
            Bar Chart
          </MenuItem>
          <MenuItem
            icon={<PieChartOutlineOutlinedIcon />}
            component={<Link to="/pie" className="link" />}
          >
            Pie Chart
          </MenuItem>
          <MenuItem
            icon={<TimelineOutlinedIcon />}
            component={<Link to="/line" className="link" />}
          >
            Line Chart
          </MenuItem>
          <MenuItem
            icon={<MapOutlinedIcon />}
            component={<Link to="/geography" className="link" />}
          >
            Geography Chart
          </MenuItem>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MySidebar;
