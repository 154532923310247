import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Snackbar, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const S3PresignedUrlsTable = () => {
  const axiosPrivate = useAxiosPrivate();
  const reactorId = 2; // Temp fix until we have reactor selector in Webapp.
  const endpoint = `/events/offline_data/${reactorId}`;
  const uploadEndpoint = `/events/offline_data/${reactorId}/add`;
  const [urlsData, setUrlsData] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    fetchPresignedUrls();
  }, [axiosPrivate, endpoint]);

  const fetchPresignedUrls = async () => {
    try {
      const response = await axiosPrivate.get(endpoint);
      const events = response.data.data.events || [];
      const files = events.flatMap(event => event.files.map(file => ({ ...file, eventId: event.id })));
      setUrlsData(files);
    } catch (err) {
      setSnackbarMessage(`Error fetching URLs: ${err.response?.data?.message || err.message}`);
      setSnackbarOpen(true);
    }
  };

  const handleDownload = async (file) => {
    try {
      const response = await axiosPrivate.get(`/events/${reactorId}/${file.eventId}`);
      const event = response.data.data.event || {};
      const fileData = event.files.find(f => f.id === file.id);
      const url = fileData?.src;
      if (url) {
        window.location.href = url;
      } else {
        throw new Error('File URL not found');
      }
    } catch (err) {
      setSnackbarMessage(`Error downloading file: ${err.response?.data?.message || err.message}`);
      setSnackbarOpen(true);
    }
  };


  const onDrop = async (acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append('file', file);
    });

    try {
      await axiosPrivate.post(uploadEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSnackbarMessage('Files uploaded successfully');
      setSnackbarOpen(true);
      fetchPresignedUrls();
    } catch (err) {
      setSnackbarMessage(`Error uploading files: ${err.response?.data?.message || err.message}`);
      setSnackbarOpen(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['.dat', ''], // Accept .dat files and files without extensions
    multiple: true,
  });

  return (
    <>
      <Box {...getRootProps()} border={1} borderRadius={4} p={2} mb={2} textAlign="center">
        <input {...getInputProps()} />
        <p>Drag and drop .dat files or files without extensions here, or click to select files to upload.</p>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {urlsData.map((file, index) => (
              <TableRow key={index}>
                <TableCell>{file.filename.split('/').pop()}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleDownload(file)}>
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default S3PresignedUrlsTable;
