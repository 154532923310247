import React, { useMemo, useCallback, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Button, Snackbar, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/en-gb';
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { tokens } from '../../theme';


const ExperimentalPeriodTable = () => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const endpoint = "/reporting/1/experimental_period";
  const queryKey = "ExperimentalPeriods";
  const [tableData, setTableData] = useState([]);
  const [creatingRow, setCreatingRow] = useState(null);
  const [customValues, setCustomValues] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch reports data
  const { isLoading, isError } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await axiosPrivate.get(endpoint);
      const experimental_periods = response.data.data.experimental_periods || [];
      setTableData(experimental_periods);
      return experimental_periods;
    },
    refetchOnWindowFocus: false,
  });

  // Mutation to create a new report
  const createReport = useMutation({
    mutationFn: async (newReport) => {
      await axiosPrivate.post(`${endpoint}/add`, newReport);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
    },
    onError: (err) => {
      setSnackbarMessage(`Error creating row: ${err.response?.data?.data?.message || err.message}`);
      setSnackbarOpen(true);
    },
  });

  // Mutation to update an existing report
  const updateReport = useMutation({
    mutationFn: async (updatedReport) => {
      await axiosPrivate.post(`${endpoint}/${updatedReport.id}`, updatedReport);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
    },
    onError: (err) => {
      setSnackbarMessage(`Error updating row: ${err.response?.data?.data?.message || err.message}`);
      setSnackbarOpen(true);
    },
  });

  // Handle row save event with error handling
  const handleEditingRowSave = useCallback(async ({ exitEditingMode, row, values }) => {
    const changedValues = {};
    Object.keys(values).forEach((key) => {
      const originalValue = row.original[key];
      const newValue = key === 'started_at' || key === 'ended_at' ? values[key] : values[key];

      // Handle moment objects for datetime fields and handle null values
      if (key === 'started_at' || key === 'ended_at') {
        if (!originalValue && newValue) {
          changedValues[key] = newValue;
        } else if (originalValue && newValue && !moment(originalValue).isSame(moment(newValue))) {
          changedValues[key] = newValue;
        }
      } else if (originalValue !== newValue) {
        changedValues[key] = newValue;
      }
    });

    if (Object.keys(changedValues).length === 0) {
      setSnackbarMessage('No changes to save');
      setSnackbarOpen(true);
      exitEditingMode();
      return;
    }

    try {
      await updateReport.mutateAsync({ id: row.original.id, ...changedValues });
      setTableData((prev) => {
        const newData = [...prev];
        newData[row.index] = { ...row.original, ...changedValues };
        return newData;
      });
      setSnackbarMessage('Row updated successfully');
    } catch (err) {
      setSnackbarMessage(`Error editing row: ${err.response?.data?.data?.message || err.message}`);
    }
    setSnackbarOpen(true);
    exitEditingMode();
  }, [updateReport, customValues]);

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: 'Id',
      enableEditing: false,
      enableColumnOrdering: true,
      enableSorting: true,
      size: 80
    },
    { accessorKey: 'experiment_id', header: 'Experimental ID' },
    {
        accessorKey: 'started_at',
        header: 'Start date',
        Cell: ({ cell }) => {
            const date = cell.getValue();
            return date ? moment(date).format('ddd, DD MMM YYYY HH:mm:ss') : '';
          },
        Edit: ({ cell }) => (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DateTimePicker
              value={
                customValues.started_at !== undefined
                  ? customValues.started_at
                    ? moment(customValues.started_at)
                    : null
                  : cell.getValue()
                  ? moment(cell.getValue())
                  : null
              }
              onChange={(newValue) => {
                setCustomValues((prev) => ({
                  ...prev,
                  started_at: newValue?.toISOString() || null,
                }));
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small',
                },
                actionBar: {
                  actions: ["cancel", "accept"],
                  sx: {
                    "& button": {
                      color: colors.grey[100],
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: 'ended_at',
        header: 'End date',
        Cell: ({ cell }) => {
            const date = cell.getValue();
            return date ? moment(date).format('ddd, DD MMM YYYY HH:mm:ss') : '';
          },
        Edit: ({ cell }) => (
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
            <DateTimePicker
              value={
                customValues.ended_at !== undefined
                  ? customValues.ended_at
                    ? moment(customValues.ended_at)
                    : null
                  : cell.getValue()
                  ? moment(cell.getValue())
                  : null
              }
              onChange={(newValue) => {
                setCustomValues((prev) => ({
                  ...prev,
                  ended_at: newValue?.toISOString() || null,
                }));
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: 'outlined',
                  size: 'small',
                },
                actionBar: {
                  actions: ["cancel", "accept"],
                  sx: {
                    "& button": {
                      color: colors.grey[100],
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      { accessorKey: 'description', header: 'Description' },
    ], [customValues]);

  const handleCreateRow = useCallback(({ values, table }) => {
    // Set the start and end date values from values
    values.started_at = values.started_at;
    values.ended_at = values.ended_at;

    // Create the new report
    createReport.mutate(values);
    table.setCreatingRow(null);
  }, [createReport, customValues]);

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        state={{ isLoading, showAlertBanner: isError, creatingRow }}
        initialState={{ showColumnFilters: false, sorting: [
          {
            id: 'id',
            desc: false,
          },
         ] }}
        muiToolbarAlertBannerProps={
          isError
            ? { color: 'error', children: 'Error loading data' }
            : undefined
        }
        muiTableContainerProps={{
          sx: { minHeight: '500px' },
        }}
        editDisplayMode="row"
        enableEditing
        enableColumnFilterModes
        createDisplayMode="row"
        onCreatingRowSave={handleCreateRow}
        onEditingRowSave={handleEditingRowSave}
        onCreatingRowChange={setCreatingRow}
        renderTopToolbarCustomActions={({ table }) => (
          <Button variant="contained" onClick={() => table.setCreatingRow(true)}>
            Add New Row
          </Button>
        )}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
};

export default ExperimentalPeriodTable;
