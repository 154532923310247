import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import OfflineDataUploader from "../../components/offline_reports/OfflineSensorData";
import { tokens } from "../../theme";

const OfflineData = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Offline Data Uploader" subtitle="Upload offline data here" />
      <br />
      <OfflineDataUploader />
      <br />
    </Box>
  );
};

export default OfflineData;