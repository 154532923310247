import React, { useCallback, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Button, Snackbar } from '@mui/material';
import {
  useQueryClient,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import moment from 'moment';
import 'moment/locale/en-gb';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useValidation } from './ValidationContext';

const ReportsTableComponent = ({ endpoint, queryKey, columns, contentFields, customValues, handleResetCustomValues, preprocessRow }) => {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();
  const { setValidationErrors } = useValidation();
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Fetch reports data
  const { data: reports = [], isLoading, isError } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await axiosPrivate.get(endpoint);
      return response.data.data.reports || [];
    },
    refetchOnWindowFocus: false,
  });

  // Mutation to create a new report
  const createReport = useMutation({
    mutationFn: async (newReport) => {
      await axiosPrivate.post(`${endpoint}/add`, newReport);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey]);
      setValidationErrors({});
    },
    onError: (error) => {
      // Show error in Snackbar
      const errorMessage =
        error.response?.data?.data?.message || 'Failed to add new report. Please try again.';
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
  });

  const validateValues = (values) => {
    const errors = {};
  
    // Validate sample_collected_at
    if (!values.sample_collected_at || !moment(values.sample_collected_at).isValid()) {
      errors.sample_collected_at = 'Invalid date and time';
    }
  
    // Validate sample_analysed_at
    if (!values.sample_analysed_at || !moment(values.sample_analysed_at).isValid()) {
      errors.sample_analysed_at = 'Invalid date and time';
    }
  
    // Check if sample_analysed_at is equal to or after sample_collected_at
    if (
      values.sample_collected_at &&
      values.sample_analysed_at &&
      moment(values.sample_analysed_at).isBefore(moment(values.sample_collected_at))
    ) {
      errors.sample_analysed_at = 'Analysed at timestamp must be equal to or after collected at timestamp';
    }
  
    // Validate content fields
    if (values.content) {
      Object.keys(values.content).forEach((key) => {
        const value = values.content[key];
        if (value !== undefined && value !== null && isNaN(value)) {
          errors[`content.${key}`] = 'Must be a valid number';
        }
      });
    }
  
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCreateRow = useCallback(({ values, table }) => {
    let mergedValues = {
      ...values,
      ...customValues,
    };
    let formattedValues = transformValues(mergedValues);
    formattedValues.content = formattedValues.content || {};
    contentFields.forEach((field) => {
      if (!(field in formattedValues.content)) {
        formattedValues.content[field] = null;
      }
    });
    if (!validateValues(formattedValues)) return;
    if (preprocessRow) {
      formattedValues = preprocessRow(formattedValues);
    }
    handleResetCustomValues();
    createReport.mutate(formattedValues);
    table.setCreatingRow(null);
  }, [createReport, contentFields, validateValues, preprocessRow]);

  const transformValues = (values) => {
    const content = {};
    Object.keys(values).forEach((key) => {
      if (key.startsWith('content.')) {
        const contentKey = key.replace('content.', '');
        content[contentKey] = values[key] || null;
        delete values[key];
      }
    });
    return { ...values, content };
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={reports}
      state={{ isLoading, showAlertBanner: isError }}
      muiToolbarAlertBannerProps={
        isError
          ? { color: 'error', children: 'Error loading data' }
          : undefined
      }
      muiTableContainerProps={{
        sx: { minHeight: '500px' },
      }}
      initialState={{ showColumnFilters: false, sorting: [
        {
          id: 'id',
          desc: false,
        },
       ] }}
       editingRow={{
        id: null,
        description: '',
        sample_id: '',
        sample_collected_at: '',
        sample_analysed_at: '',
        content: contentFields.reduce((acc, field) => {
          acc[field] = '';
          return acc;
        }, {}),
      }}
      editDisplayMode="row"
      enableEditing
      enableColumnFilterModes
      createDisplayMode="row"
      onCreatingRowSave={handleCreateRow}
      renderTopToolbarCustomActions={({ table }) => (
        <Button variant="contained" onClick={() => table.setCreatingRow(true)}>
          Add New Row
        </Button>
      )}
    />
  );
};

export default ReportsTableComponent;