import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider, Box, GlobalStyles } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { ProSidebarProvider } from "react-pro-sidebar";
import Form from "./scenes/form";
import Topbar from "./scenes/global/Topbar";
import MySidebar from "./scenes/global/MySidebar";
import Login from "./components/Login";
import Dashboard from "./scenes/dashboard";
import Missing from "./components/Missing";
import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import Admin from "./scenes/admin/users_table";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Pie from "./scenes/pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";
import { Reset } from "./components/ResetPassword";
import { RequestReset } from "./components/RequestReset";
import Unauthorized from "./components/Unauthorized";
import AdminPermissions from "./scenes/admin/users_permissions";
import OfflineReporting from "./scenes/report";
import OfflineData from "./scenes/offline_data_uploader";
import UserProfile from "./components/Profile";
import { ROLES } from "./constants/roles";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  return (
    <>
      <GlobalStyles
        styles={{
          html: { overscrollBehaviorX: "none" },
          body: { overscrollBehaviorX: "none" },
        }}
      />
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: isSidebarCollapsed ? "80px 1fr" : "240px 1fr", // Dynamically adjust the grid
              gridTemplateRows: "auto 1fr",
              gridTemplateAreas: `
                "sidebar topbar"
                "sidebar content"
              `,
              height: "100vh",
              transition: "grid-template-columns 0.3s ease-in-out", // Smooth transition
            }}
          >
            <ProSidebarProvider>
              <Box gridArea="sidebar">
                <MySidebar
                  isCollapsed={isSidebarCollapsed}
                  setIsCollapsed={setIsSidebarCollapsed}
                />
              </Box>
            </ProSidebarProvider>
            <Box gridArea="topbar" sx={{ width: "100%" }}>
              <Topbar setIsSidebar={setIsSidebarCollapsed} />
            </Box>
            <Box gridArea="content" sx={{ p: 3, overflow: "auto" }}>
              <Routes>
                <Route path="login" element={<Login />} />
                <Route
                  path="reset"
                  element={<Reset endpoint="/user/reset_finalize" />}
                />
                <Route
                  path="confirm"
                  element={<Reset endpoint="/user/finalize" />}
                />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="request_reset" element={<RequestReset />} />

                <Route element={<PersistLogin />}>
                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route path="admin" element={<Admin />} />
                    <Route
                      path="admin/:userId/permissions"
                      element={<AdminPermissions />}
                    />
                  </Route>

                  <Route
                    element={
                      <RequireAuth
                        allowedRoles={[ROLES.Operator, ROLES.Admin]}
                      />
                    }
                  >
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/profile" element={<UserProfile />} />
                    <Route path="/contacts" element={<Contacts />} />
                    <Route path="/offline-reports" element={<OfflineReporting />} />
                    <Route path="/form" element={<Form />} />
                    <Route path="/bar" element={<Bar />} />
                    <Route path="/pie" element={<Pie />} />
                    <Route path="/line" element={<Line />} />
                    <Route path="/geography" element={<Geography />} />
                    <Route path="/offline-data" element={<OfflineData />} />
                  </Route>
                </Route>

                {/* Catch all */}
                <Route path="*" element={<Missing />} />
              </Routes>
            </Box>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
