import React, { useMemo, useState, useCallback } from 'react';
import { TextField, useTheme } from '@mui/material';
import ReportsTableComponent from './OfflineReportsTable';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import { useValidation } from './ValidationContext';
import { tokens } from '../../theme';

const ReducingSugarsTable = () => {
  const { validationErrors, setValidationErrors } = useValidation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const contentFields = [
    'wavelength',
    'measured_inlet_d1', 'measured_inlet_d2', 'measured_center_d1', 'measured_center_d2',
    'measured_outlet_d1', 'measured_outlet_d2', 'measured_harvest_d1', 'measured_harvest_d2',
  ];

  const initialCustomValues = {
    sample_collected_at: moment(),
    sample_analysed_at: moment(),
    ...contentFields.reduce((acc, field) => {
      acc[`content.${field}`] = null;
      return acc;
    }, {}),
  };
  const [customValues, setCustomValues] = useState(initialCustomValues);

  const handleResetCustomValues = useCallback(() => {
    setCustomValues(initialCustomValues);
  }, []);

  const columns = useMemo(() => [
    {
      accessorKey: 'id',
      header: 'Id',
      enableEditing: false,
      enableColumnOrdering: true,
      enableSorting: true,
      size: 80
    },
    {
      accessorKey: 'sample_collected_at',
      header: 'Sample Collected At',
      Cell: ({ cell }) => {
        const date = cell.getValue();
        return date ? moment(date).format('ddd, DD MMM YYYY HH:mm:ss') : ''; // Format date for display
      },
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
          <DateTimePicker
            value={
              customValues.sample_collected_at !== undefined
                ? customValues.sample_collected_at
                  ? moment(customValues.sample_collected_at)
                  : null
                : cell.getValue()
                ? moment(cell.getValue())
                : null
            }
            onChange={(newValue) => {
              setCustomValues((prev) => ({
                ...prev,
                sample_collected_at: newValue?.toISOString() || null,
              }));
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
                error: !!validationErrors.sample_collected_at,
                helperText: validationErrors.sample_collected_at || '',
              },
              actionBar: {
                actions: ["cancel", "accept"],
                sx: {
                  "& button": {
                    color: colors.grey[100],
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      ),
    },
    {
      accessorKey: 'sample_analysed_at',
      header: 'Sample Analysed At',
      Cell: ({ cell }) => {
        const date = cell.getValue();
        return date ? moment(date).format('ddd, DD MMM YYYY HH:mm:ss') : '';
      },
      Edit: ({ cell }) => (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
          <DateTimePicker
            value={
                customValues.sample_analysed_at !== undefined
                ? customValues.sample_analysed_at
                  ? moment(customValues.sample_analysed_at)
                  : null
                : cell.getValue()
                ? moment(cell.getValue())
                : null
            }
            onChange={(newValue) => {
              setCustomValues((prev) => ({
                ...prev,
                sample_analysed_at: newValue?.toISOString() || null,
              }));
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                variant: 'outlined',
                size: 'small',
                error: !!validationErrors.sample_analysed_at,
                helperText: validationErrors.sample_analysed_at || '',
              },
              actionBar: {
                actions: ["cancel", "accept"],
                sx: {
                  "& button": {
                    color: colors.grey[100],
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      ),
    },
    { accessorKey: 'sample_id', header: 'Sample ID' },
    { accessorKey: 'description', header: 'Description' },
    ...contentFields.map((field) => {
        if (field === 'wavelength') {
          // Static column for Measurement Density
          return {
            accessorKey: `content.${field}`,
            header: 'Wavelength',
            enableEditing: false,
            Cell: () => '16nm', // Static value
          };
        }
        // Other dynamic content fields
        return {
          accessorKey: `content.${field}`,
          header: `${field.replace(/_/g, ' ')}`,
          Edit: ({ cell }) => (
            <TextField
              value={customValues[`content.${field}`] || ''}
              onChange={(e) =>
                setCustomValues((prev) => ({
                  ...prev,
                  [`content.${field}`]: e.target.value,
                }))
              }
              fullWidth
              size="small"
              variant="outlined"
              error={!!validationErrors[`content.${field}`]}
              helperText={validationErrors[`content.${field}`] || ''}
            />
          ),
        };
      }),
    ], [contentFields]);

    const handleCreateRow = useCallback(
        (row) => {
          // Preprocess the new row to include the static value
          const processedRow = {
            ...row,
            content: {
              ...row.content,
              wavelength: '16nm', // Ensure wavelength is always included
            },
          };
    
          return processedRow;
        },
        []
      );

  return (
    <ReportsTableComponent
      endpoint="/reporting/1/reducingsugars"
      queryKey="ReducingSugards"
      columns={columns}
      contentFields={contentFields}
      customValues={customValues}
      preprocessRow={handleCreateRow}
      handleResetCustomValues={handleResetCustomValues}
    />
  );
};

export default ReducingSugarsTable;
